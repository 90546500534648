import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import VideoAutoplay from '../atoms/video-autoplay';

// Logos
// import TaglineReversedLogo from '../logos/logo-tagline-reversed.svg';

// Components
import { HeroWrapper } from './hero';

const HeroHomepage = ({ title, video, tagline, ...props }) => {
  return (
    <HeroWrapper className={cn('text-center', props.className)}>
      <div
        className={cn(
          'xl:container mobile:px-4 tablet:px-8 lg:px-16 relative',
          props.pt || 'pt-8 lg:pt-10',
          props.pb || 'pb-8 lg:pb-14'
        )}
      >
        <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
          <div className="col-span-12 lg:col-start-2 lg:col-span-10">
            <div className="relative rounded-t-[8px] lg:rounded-t-[17px] overflow-hidden">
              {video?.id && (
                <VideoAutoplay
                  title={title}
                  id={video?.id}
                  platform={video?.platform}
                  thumbnail={video?.thumbnail}
                  videoOnLoop={false}
                />
              )}
            </div>
            {(tagline || title) && (
              <div className="bg-white bg-opacity-10 shadow-hero rounded-b-[8px] lg:rounded-b-[17px] px-2 py-6 lg:pt-10 lg:pb-14">
                {title && (
                  <h2 className="text-h4 xs:text-h2.5-m md:text-h2.5  mb-2">
                    {title}
                  </h2>
                )}
                {tagline && (
                  <p className="text-body-md md:text-body-lg">{tagline}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </HeroWrapper>
  );
};

HeroHomepage.defaultProps = {
  video: {
    platform: '',
    id: '',
    thumbnail: {},
  },
  tagline: '',
};

HeroHomepage.propTypes = {
  title: PropTypes.string.isRequired,
  video: PropTypes.shape({
    platform: PropTypes.string,
    id: PropTypes.string,
    thumbnail: PropTypes.object,
  }),
  tagline: PropTypes.string,
};

export default HeroHomepage;
